// DS V2
import { Button, Typography, Modal, TextInput } from '@ds';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useAutoUnblockCloudIpMutation } from '@/apollo/generated';
import { useAlert } from '@/contexts/alert-context';

interface FormData {
  email: string;
  marketKey: string;
  ticker: string;
}

interface Props {
  onClose(): void;
  open: boolean;
}

const UnblockCloudIpModal: React.ComponentType<Props> = ({ onClose, open }) => {
  const { formatAndShowError } = useAlert();

  const [autoUnblockCloudIp] = useAutoUnblockCloudIpMutation();

  const { formState, handleSubmit, register, reset } = useForm<FormData>({
    defaultValues: {
      email: '',
      marketKey: '',
      ticker: '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await autoUnblockCloudIp({
        variables: {
          email: data.email,
          marketKey: data.marketKey,
          ticker: data.ticker,
        },
      });
      onClose();
    } catch (error) {
      formatAndShowError(error);
    }
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal className="sm:max-w-2xl" open={open} onClose={handleClose}>
      <form className="space-y-8" onSubmit={onSubmit}>
        <div className="space-y-4">
          <Typography component="h2" variant="text-label-lg">
            Unblock IP address
          </Typography>
          <div className="space-y-1">
            <Typography component="h3" variant="text-body-md">
              Please enter your email address, market key, and company&#39;s
              ticker.
            </Typography>
            <Typography component="h3" variant="text-body-md">
              Once confirmed, an email will be sent to you shortly.
            </Typography>
          </div>
        </div>
        <div className="space-y-4">
          <TextInput
            {...register('email', { required: true })}
            error={!!formState.errors.email?.message}
            helperText={formState.errors.email?.message}
            id="email"
            label="Email*"
            type="email"
          />

          <Typography
            className="text-gray-700"
            component="h3"
            variant="text-label-sm"
          >
            Market key*
          </Typography>
          <select
            {...register('marketKey', { max: 3, required: true })}
            className={clsx(
              'input',
              'rounded-lg',
              formState.errors.marketKey && 'input-error'
            )}
          >
            <option value="ASX">ASX</option>
            <option value="LSE">LSE</option>
            <option value="AQSE">AQSE</option>
          </select>

          <TextInput
            {...register('ticker', { max: 6, required: true })}
            error={!!formState.errors.ticker?.message}
            helperText={formState.errors.ticker?.message}
            id="ticker"
            label="Ticker*"
            max={6}
          />
        </div>
        <div className="flex flex-col gap-4 sm:flex-row-reverse">
          <Button
            className="flex-1"
            disabled={formState.isSubmitting}
            type="submit"
          >
            Confirm
          </Button>
          <Button
            className="flex-1"
            disabled={formState.isSubmitting}
            variant="secondary-gray"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default UnblockCloudIpModal;
