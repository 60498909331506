import { useCurrentCompanyUserQuery } from '@/apollo/generated';
import ErrorComponent from '@/components/utils/error-component';
import Redirect from '@/components/utils/redirect';
import { formatError } from '@/utils/error-helpers';
import routes from '@/utils/routes';

export const Index: React.ComponentType = () => {
  const { data, error, loading } = useCurrentCompanyUserQuery();

  if (data?.currentCompanyUser) {
    const profileUser = data.currentCompanyUser.companyProfileUsers[0];

    if (profileUser) {
      return (
        <Redirect
          force
          href={routes.home.href(profileUser.profile.ticker.marketListingKey)}
        />
      );
    }

    return <Redirect force href={routes.noOrganisation.href} />;
  }

  if (error) {
    // When suspicious cloud ip is detected
    if (formatError(error) === 'Access denied!') {
      return <ErrorComponent />;
    }
    return <ErrorComponent statusCode={500} />;
  }

  if (loading) {
    return <div className="bg-amplify-green-700 min-h-full" />;
  }

  return <Redirect force href={routes.auth.login.href} />;
};

export default Index;
