import { useEffect } from 'react';
import Router from 'next/router';
import routes from '@/utils/routes';

interface Props {
  force?: boolean;
  href: string;
  redirectUrl?: string;
}

const Redirect: React.ComponentType<Props> = ({ force, href, redirectUrl }) => {
  useEffect(() => {
    if (force) {
      if (href === routes.auth.login.href) {
        Router.replace({
          pathname: href,
          query: {
            redirect: redirectUrl,
          },
        });
      } else {
        window.location.replace(href);
      }
    } else {
      Router.replace(href);
    }
  }, [force, redirectUrl, href]);

  return null;
};

export default Redirect;
