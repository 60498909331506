import analytics from '@analytics';

export async function login(
  email: string,
  password: string,
  rememberMe = true
) {
  const res = await fetch(`${process.env.NEXT_PUBLIC_AUTH_URI}/login`, {
    body: JSON.stringify({
      company_user: {
        email,
        password,
        remember_me: rememberMe,
      },
    }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  const json = await res.json();

  if (json.error) {
    throw new Error(json.error.message);
  }
}

export async function logout() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_AUTH_URI}/logout`, {
    credentials: 'include',
    method: 'POST',
  });

  const json = await res.json();

  if (json.error) {
    throw new Error(json.error.message);
  }

  analytics.reset();
}
