// DS V2
import { useMemo, useState } from 'react';
import { Button, Typography } from '@ds';
import Link from 'next/link';
import Script from 'next/script';
import { useCurrentCompanyUserQuery } from '@/apollo/generated';
import UnblockCloudIpModal from '@/components/modals/unblock-cloud-ip-modal';
import { useAlert } from '@/contexts/alert-context';
import { useContactFresh } from '@/contexts/contact-fresh-context';
import { logout } from '@/utils/auth-helpers';
import routes from '@/utils/routes';

interface Props {
  statusCode?: number;
}

const ErrorComponent: React.ComponentType<Props> = ({ statusCode }) => {
  const { formatAndShowError } = useAlert();
  const { showContactFreshModal } = useContactFresh();
  const [isShowUnblockCloudIpModal, setIsShowUnblockCloudIpModal] =
    useState(false);
  function onClickContactUs() {
    window.grecaptcha.ready(() => {
      if (process.env.NEXT_PUBLIC_RECAPTCHA_KEY) {
        window.grecaptcha
          .execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, {
            action: 'submit',
          })
          .then(() => showContactFreshModal(1));
      }
    });
  }

  function onClickInvestorHub() {
    window.grecaptcha.ready(() => {
      if (process.env.NEXT_PUBLIC_RECAPTCHA_KEY) {
        window.grecaptcha
          .execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, {
            action: 'submit',
          })
          .then(() => setIsShowUnblockCloudIpModal(true));
      }
    });
  }

  const title = useMemo(() => {
    switch (statusCode) {
      case 403:
        return 'Access forbidden';
      case 404:
        return 'Page not found';
      case 500:
        return 'Something went wrong, please try again.';

      // Default state for suspicious cloud ip detected
      default:
        return null;
    }
  }, [statusCode]);

  const content = useMemo(() => {
    switch (statusCode) {
      case 403:
        return (
          <div className="space-y-8">
            <Typography
              className="text-center text-white"
              variant="text-body-md"
            >
              It looks like you don&#39;t have permission to view this page.
            </Typography>
            <Typography
              className="text-center text-white"
              variant="text-body-md"
            >
              Please ensure that you have the correct sign in information and
              try again.
            </Typography>
            <Typography
              className="text-center text-white"
              variant="text-body-md"
            >
              {'If the issue persists, please '}
              <span
                className="underline"
                role="button"
                tabIndex={-1}
                onClick={onClickContactUs}
              >
                contact support
              </span>
              {'.'}
            </Typography>
          </div>
        );
      case 404:
        return (
          <div className="space-y-8">
            <Typography
              className="text-center text-white"
              variant="text-body-md"
            >
              It looks like this page doesn&#39;t exist!
            </Typography>
            <Typography
              className="text-center text-white"
              variant="text-body-md"
            >
              Please ensure that you have entered it correctly in your browser.
            </Typography>
            <Typography
              className="text-center text-white"
              variant="text-body-md"
            >
              {'If the issue persists, please '}
              <span
                className="underline"
                role="button"
                tabIndex={-1}
                onClick={onClickContactUs}
              >
                contact support
              </span>
              {'.'}
            </Typography>
          </div>
        );
      case 500:
        return (
          <Typography className="text-center text-white" variant="text-body-md">
            {'If the issue persists, please '}
            <span
              className="underline"
              role="button"
              tabIndex={-1}
              onClick={onClickContactUs}
            >
              contact support
            </span>
            {'.'}
          </Typography>
        );

      // Default state for suspicious cloud ip detected
      default:
        return (
          <>
            <Typography
              className="hidden max-w-screen-md text-center text-white sm:block"
              variant="text-body-md"
            >
              We&apos;ve detected suspicious activity from your IP address, so
              to keep your data safe we&apos;ve suspended your access to
              InvestorHub. If you believe this is an error, please contact us
              for an immediate resolution.
            </Typography>
            <Typography
              className="max-w-screen-md text-center text-white sm:hidden"
              variant="text-body-md"
            >
              We&apos;ve detected suspicious activity from your IP address.
              Please contact us if you believe this is in error.
            </Typography>
          </>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCode]);

  const { data } = useCurrentCompanyUserQuery();

  const action = useMemo(() => {
    switch (statusCode) {
      case 404:
        return (
          <Link
            className="rounded-lg bg-white/10 px-3 py-2 text-white transition-colors hover:bg-white/20"
            href={'/'}
          >
            Back to home
          </Link>
        );

      case undefined:
        return (
          <Button variant="secondary-gray" onClick={onClickInvestorHub}>
            Contact InvestorHub
          </Button>
        );

      default:
        return data?.currentCompanyUser ? (
          <Button
            className="w-full sm:w-auto"
            variant="secondary-gray"
            onClick={handleLogout}
          >
            Sign out
          </Button>
        ) : (
          <Link className="w-full sm:w-auto" href={routes.auth.login.href}>
            <Button className="w-full" variant="secondary-gray">
              Sign in
            </Button>
          </Link>
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, statusCode]);

  async function handleLogout() {
    try {
      await logout();

      window.location.assign(routes.auth.login.href);
    } catch (error) {
      formatAndShowError(error);
    }
  }

  return (
    <div className="bg-amplify-green-700 flex min-h-full flex-col items-center space-y-8 px-4 pt-[34px] sm:px-0 sm:py-32">
      {/* Default state for suspicious cloud ip detected */}
      <Typography className="text-center text-white" variant="text-display-lg">
        {statusCode ?? 'Access denied'}
      </Typography>
      <div className="space-y-8">
        {title && (
          <Typography
            className="text-center text-white"
            variant="text-heading-sm"
          >
            {title}
          </Typography>
        )}
        {content}
      </div>
      {action}
      <Script
        id="recaptcha"
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
        strategy="afterInteractive"
      />
      {isShowUnblockCloudIpModal && (
        <UnblockCloudIpModal
          open={isShowUnblockCloudIpModal}
          onClose={() => setIsShowUnblockCloudIpModal(false)}
        />
      )}
    </div>
  );
};

export default ErrorComponent;
